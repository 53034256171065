
import { get, post, put } from '@shared/http'
import { objValuesHandler } from '@shared/util'
import { noSymbolValue } from '@api/static/index'
import URL from './goodsUrl'

const GOODS_API = {
  remarks (params) {
    return put(URL.remarks, params)
  },
  getPurchaseStatistics (params) {
    return post(URL.purchaseStatistics, params).then(res => {
      let data = []
      if (res.success) {
        data = res.data.map(item => {
          return {
            label: item.des,
            value: item.dayNum,
            extra: {
              amount: item.count
            }
          }
        })
      }
      return data
    })
  },
  getPurchaseTabs (params) {
    return post(URL.purchaseTabs, params).then(res => {
      let data = []
      if (res.success) {
        data = res.data.map(item => {
          return {
            index: item.tabType,
            name: item.des,
            value: item.tabType,
            amount: item.count
          }
        })
      }
      return data
    })
  },
  getPurchaseTableList (params) {
    return post(URL.purchaseList, params)
  },
  // 拆包次品列表
  getDefectiveTableList (params) {
    return post(URL.defectiveList, objValuesHandler(params))
  },
  // 质检次品列表
  getQaDefectiveTableList (params) {
    return post(URL.qaDefectiveList, objValuesHandler(params))
  },
  getGroupTabs (params) {
    return post(URL.groupTabs, params).then(res => {
      let data = []
      let tabMap = {
        3: {
          status: 'danger',
          statusText: '预警!'
        },
        5: {
          status: 'danger',
          statusText: '严重预警!'
        },
        7: {
          status: 'danger',
          statusText: '严重预警!'
        }
      }
      if (res.success) {
        data = res.data.map(item => {
          let temp = {
            index: item.tabType,
            name: item.des,
            value: item.tabType,
            amount: item.count
          }
          if (tabMap[item.tabType]) {
            temp['status'] = tabMap[item.tabType]['status']
            temp['statusText'] = tabMap[item.tabType]['statusText']
          }
          return temp
        })
      }
      return data
    })
  },
  getGroupList (params) {
    return post(URL.groupList, params)
  },
  groupSplite (params) {
    return post(URL.groupSplite, params)
  },
  groupGenerateShippedBill (params) {
    return post(URL.groupGenerateShippedBill, params)
  },

  // 发货列表
  invoiceList (params) {
    return post(URL.invoiceList, params)
  },

  getInvoiceDetail (id) {
    return get(`${URL.invoiceDetail}/${id}`)
  },

  printNo (id) {
    return get(`${URL.printNo}/${id}`)
  },

  logisticsCompany () {
    return get(URL.logisticsCompany)
  },

  modifyLogisticsNo (data) {
    return put(URL.modifyLogisticsNo, data)
  },

  modifyInvoice (data) {
    return put(URL.modifyInvoice, data)
  },

  printInvoice (id) {
    return get(`${URL.printInvoice}/${id}`)
  },

  getLogisticsInfo (data) {
    return get(URL.logisticsInfo, data)
  },

  batchPrintNo (data) {
    get(URL.printNo, data)
  },

  exportExcel (data) {
    return get(URL.exportExcel, data)
  },

  getStockOutList (params) {
    return get(URL.stockOutList, params)
  },

  doStockOutApply (params) {
    return post(URL.stockOutApply, params)
  },
  // 商品数据列表
  getProductDashboardList (params) {
    return get(URL.productDashboardList, params)
  },
  // 商品颜色数据
  getProductColors (params) {
    return get(URL.productColor, params).then(res => {
      let result = res.data || []
      let noDataIndex = result.findIndex(item => item.value === '')
      if (result.length > 0 && noDataIndex !== -1) {
        result.splice(noDataIndex, 1)
        result.unshift({
          label: '无',
          value: noSymbolValue
        })
      }
      return result
    })
  },
  // 自配送物流
  selfDistributionLogistics (params) {
    return post(URL.selfDistributionLogistics, params)
  },
  // 商品SKU列表
  getSupplierSkuList (params) {
    return get(URL.supplierSkuList, params)
  },
  // 批量修改SKU状态
  updateSupplierSkuStatus (params) {
    return put(URL.supplierSkuStatus, params)
  },
  // 获取尺码表信息
  getProductSize (params) {
    return get(URL.productSize, params)
  }
}
export default GOODS_API
