<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <SlTableToolbar>
        <SlButton
          type="primary"
          boxShadow="primary"
          :loading="loading"
          :disabled="!tableData.length"
          @click="exportList"
        >当前页面导出</SlButton>
      </SlTableToolbar>
      <!-- 表格区域包含分页 -->
      <SlTable
        ref="table"
        :tableData="tableData"
        :columns="columns"
        :selection="false"
        :operate="false"
        :tooltip="false"
        rowKey="id"
      ></SlTable>
    </SlListView>
  </div>
</template>

<script>
import CommonUrl from '@api/url.js'
import GoodsApi from '@api/goods'
import GoodsUrl from '@api/goods/goodsUrl'
import { exportFileFromRemote, date, errorMessageTip } from '@shared/util'

export default {
  name: 'DefectiveList',
  data () {
    return {
      loading: false,
      tableLoading: false,
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '发货单号',
          name: 'deliveryNumber'
        },
        {
          type: 'input',
          label: '物流单号',
          name: 'logisticsNumber'
        },
        {
          type: 'input',
          label: 'SKU',
          name: 'sku'
        },
        {
          type: 'single-select',
          label: '异常类型',
          name: 'exceptionType',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'DEFECTIVE_EXCEPTION_ENUM' }
          }
        },
        {
          type: 'single-select',
          label: '异常处理方式',
          name: 'exceptionDealType',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'DEFECTIVE_EXCEPTION_DEAL_ENUM' }
          }
        },
        {
          type: 'single-select',
          label: '处理状态',
          name: 'handleStatus',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'PURCHASE_EXCEPTION_HANDLE_STATUS_ENUM' }
          }
        },
        {
          type: 'date',
          label: '创建时间',
          name: 'createdAts',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        }
      ],
      columns: [
        {
          prop: '',
          label: '基本信息',
          width: '200',
          isInImg: 'imageUrl',
          pre: {
            supplierItemNo: '供方货号',
            supplierSkuCode: '商家SKU',
            skuCode: 'SKU'
          },
          data: {
            imgSize: '5rem'
          }
        },
        {
          prop: 'name',
          label: '商品名称',
          width: '150',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-tooltip placement="top" effect="light">
                <div slot="content" style="max-width:300px">{row.name}</div>
                <p v-slClamp={{ clamp: 2 }}>{row.name}</p>
              </el-tooltip>
            )
          }
        },
        {
          prop: 'attributes',
          label: '销售属性'
        },
        {
          prop: 'exceptionTypeName',
          label: '异常类型'
        },
        {
          prop: 'handleStatusName',
          label: '处理状态'
        },
        {
          prop: 'exceptionQuantity',
          label: '异常数量'
        },
        {
          prop: 'exceptionDealTypeName',
          label: '处理方式'
        },
        {
          prop: 'supplierItemNo',
          label: '供方货号'
        },
        {
          prop: 'supplierSkuCode',
          label: '商家SKU编码'
        },
        {
          prop: 'merchantTagSize',
          label: '商家吊牌尺码'
        },
        {
          prop: 'remark',
          label: '详情',
          width: '150'
        },
        {
          prop: 'purchaseOrderNum',
          label: '采购单号'
        },
        {
          prop: 'orderNumber',
          label: '发货单号'
        },
        {
          prop: '',
          label: '物流单号',
          width: '150',
          pre: {
            oldLogisticsNumber: '发货物流',
            logisticsNumber: '退货物流'
          }
        },
        {
          prop: 'operateName',
          label: '采购员'
        },
        {
          prop: '',
          label: '时间',
          width: '200',
          pre: {
            createTime: '创建时间',
            updateTime: '更新时间'
          }
        }
      ]
    }
  },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      GoodsApi.getDefectiveTableList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      let { createdAts = [], ...orther } = this.query

      return {
        ...orther,
        pageIndex,
        pageSize,
        createTimeStart: createdAts && createdAts[0] ? createdAts[0] : '',
        createTimeEnd: createdAts && createdAts[1] ? createdAts[1] : ''
      }
    },
    exportList () {
      exportFileFromRemote({
        url: GoodsUrl.defectiveListExport,
        name: `拆包次品列表_${date(+new Date(), 'yyyy-MM-dd')}.xlsx`,
        params: this.generateParams(this.page.pageSize, this.page.pageIndex),
        beforeLoad: () => {
          this.loading = true
          this.$store.dispatch('OPEN_LOADING', { isCount: false, loadingText: '导出中' })
        },
        afterLoad: () => {
          this.loading = false
          this.$store.dispatch('CLOSE_LOADING')
        },
        successFn: () => { },
        errorFn: () => {
          errorMessageTip('导出失败,请重试')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
